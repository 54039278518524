body {
  background: #ff4545 url('images/logo.png') no-repeat center center;

  background-size: cover;
margin: 0;
font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
  'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
  sans-serif;
-webkit-font-smoothing: antialiased;
-moz-osx-font-smoothing: grayscale;
}

#root {
  min-height: 95vh;
}

code {
font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
  monospace;
}

header {
  /*background: #ff4545 url('images/logo.png') no-repeat center center;*/

background-size: cover;
display: flex;
flex-direction: column;
align-items: center;
justify-content: center;
font-size: calc(10px + 2vmin);
color: white;
}

.frontpage-header {
  min-height: 85vh;
}

main {
display: flex;
flex-direction: column;
align-items: center;
justify-content: center;
font-size: calc(10px + 2vmin);
color: white;
}

.frontpage-main {
  min-height: 10vh;
}


footer {
min-height: 5vh;
display: flex;
flex-direction: row;
align-items: center;
justify-content: space-around;
font-size: calc(10px + 2vmin);
color: white;
background-color: #000;
}

.icon {
	max-height: 3vh;
	min-height: 2vh;
}

.icon:hover {
  /* filter: invert(100%); */
  background-color: #ff4545;
  border-radius:50%;
}

.preview {
  width: 20vw;
}