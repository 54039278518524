
header {
min-height: 20vh;
}

main {
min-height: 75vh;
}

.checked {
  border-radius: 10%;
  border: #ff4545 solid 1px;

}

.unchecked {
  filter: grayscale(100%) opacity(0.6);
  border-radius: 50%;
}



.disabled {
  filter: opacity(25%);
  border-radius: 50%;
  width:100px;
}



